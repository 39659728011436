<template>
  <a-card :style="backCss" :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form v-if="radioValue == '1'" layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="请输入标题" @pressEnter="getMsgTableData" v-model="queryParam.title"></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="getMsgTableData" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
          <a-col :span="4" style="display:flex;justify-content: end;float:right;">
            <a-button class="mr-3" type="primary" @click="clearReadAll" icon="book">清空已读</a-button>
            <a-button type="primary" @click="readAll" icon="book">全标已读</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div style="height:56px" v-else>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="请输入标题" @pressEnter="getTableData" v-model="templateName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="getTableData" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
          <a-col :span="4" style="display:flex;justify-content: end;float:right;">
            <a-button
              class="mr-3 delete-btn"
              @click="msgdeleteBatch"
              style="color: #fff;background-color: #ff4d4f;border-color: #ff4d4f;"
              v-if="selectedRowKeys.length"
              icon="delete"
              v-has="'announcement:list:delete'"
              >删除</a-button
            >
            <a-button type="primary" @click="add" v-has="'announcement:add'" icon="plus">新建</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div style="padding-top:10px;padding-bottom:20px;">
      <a-radio-group v-model="radioValue">
        <a-radio value="1" v-has="'announcement:list:view'">公告列表</a-radio>
        <a-radio value="2" v-has="'announcement:view'">发布公告</a-radio>
      </a-radio-group>
    </div>
    <a-table
      v-show="radioValue == '1'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="msgTableData"
      @expand="expand"
      :pagination="ipagination"
      :customRow="customRow"
      :expandedRowKeys="expandedRowKeys"
      :loading="loading"
      @change="handleTableChange2"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 446px)' }"
    >
      <span slot="readFlag" slot-scope="text, record">
        {{ record.readFlag == '1' ? '已读' : '未读' }}
      </span>
      <span slot="sender" slot-scope="text, record">
        {{ record.template && record.template.publishUser && record.template.publishUser.userName }}
      </span>
      <span slot="action" @click.stop slot-scope="text, record">
        <a v-has="'announcement:list:delete'" @click="msgdelete(record)">删除</a>
        <a @click="redMsg(record)" style="margin-left:15px">标注已读</a>
      </span>
      <a-table
        slot="expandedRowRender"
        style="background-color: #fff;"
        class="content-table"
        slot-scope="text"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
      >
        <template slot="msgContent" slot-scope="text, record">
          <div v-html="record.msgContent" style="white-space: pre-line;text-align:left"></div>
        </template>
      </a-table>
    </a-table>
    <a-table
      v-show="radioValue == '2'"
      v-has="'announcement:view'"
      ref="table"
      size="default"
      rowKey="id"
      :columns="columnsTwo"
      :dataSource="tableData"
      :pagination="ipagination"
      :loading="loading"
      :customRow="customRowTwo"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange2"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 446px)' }"
    >
      <span slot="sendTypes" slot-scope="text, record">
        <a-tag v-for="item in record.sendTypes" :key="item">{{ sendTypeMap[item] }}</a-tag>
      </span>
      <span slot="hasPublished" slot-scope="text, record">
        {{ record.hasPublished ? '已发布' : '未发布' }}
      </span>
      <span slot="publishUser" slot-scope="text, record">
        {{ record.publishUser ? record.publishUser.userName : '' }}
      </span>
      <span slot="tableScopes" slot-scope="text, record">
        <template v-for="(item, index) in record.tableScopes">
          <div v-if="index < 2" :key="item.departName">
            <a-tag>{{ item.departName }}</a-tag>
            <span>...</span>
          </div>
        </template>
      </span>
      <span slot="noReadUsers" slot-scope="text, record">
        <div v-if="record.noReadUsers.length < 4" style="display:flex;justify-content: flex-start;">
          <span v-for="item in record.noReadUsers" :key="item.userId">
            <img
              v-if="item.avatar"
              :src="item.avatar"
              style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
              alt=""
            />
            <img v-else src="./../../assets/tx.png" style="width:40px;height:40px;border-radius: 50%;" alt="" />
          </span>
        </div>
        <div v-else style="display:flex;justify-content: flex-start;">
          <img
            v-for="item in record.noReadUsers.filter((i, index) => {
              return index < 3
            })"
            style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
            :key="item.userId"
            :src="item.avatar"
          />
          <a-tooltip overlayClassName="user-msg-tooltip" color="white" placement="bottom">
            <template #title>
              <div>
                <p style="padding-left:15px;font-weight:700">未读人员</p>
                <div style="height:300px;overflow-y:auto ;">
                  <p v-for="item in record.noReadUsers" :key="item.userId">
                    <img
                      style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
                      :key="item.userId"
                      :src="item.avatar"
                    />
                    <span style="margin-left:15px">{{ item.userName }}</span>
                  </p>
                </div>
              </div>
            </template>
            <span>
              <span class="noread-user">
                <span class="iconfont icon-24gl-ellipsis icon"></span>
              </span>
            </span>
          </a-tooltip>
        </div>
      </span>
      <span @click.stop slot="action" slot-scope="text, record">
        <a v-has="'announcement:delete'" @click="deleteAnnouncement(record)">删除</a>
        <a
          v-has="'announcement:send'"
          :disabled="record.hasPublished"
          style="margin-left:15px"
          @click="sendAnnouncement(record)"
          >发布</a
        >
        <a v-has="'announcement:edit'" style="margin-left:15px" @click="editAnnouncement(record)">再次编辑</a>
        <a v-has="'announcement:edit'" style="margin-left:15px" @click="pushAnnouncement(record)">再次提醒</a>
      </span>
    </a-table>
    <show-announcement ref="ShowAnnouncement"></show-announcement>
    <anct-modal :loadData="getTableData" ref="anctModal"></anct-modal>
    <dynamic-notice ref="showDynamNotice" :path="openPath" :formData="formData" />
  </a-card>
</template>

<script>
import { filterObj, handleArrayParams } from '@/utils/util'
import { getAction, putAction, deleteAction } from '@/api/manage'
import ShowAnnouncement from '@/components/tools/ShowAnnouncement'
import anctModal from '@/components/tools/anctModal'
import { simpleDebounce } from '@/utils/util'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import DynamicNotice from '../../components/tools/DynamicNotice'
import { postAction } from '../../api/manage'
export default {
  name: 'UserAnnouncementList',
  mixins: [JeecgListMixin],
  components: {
    DynamicNotice,
    ShowAnnouncement,
    anctModal
  },
  data() {
    return {
      description: '系统通告表管理页面',
      queryParam: {},
      radioValue: '1',
      sendTypeMap: {
        SYSTEM: '系统消息',
        EMAIL: '邮件',
        WECHAT: '公众号'
      },
      sendTypeMaps: new Map()
        // .set("UNKNOWN", "未知")
        // .set("SMS", "短信")
        .set('SYSTEM', '系统消息')
        .set('EMAIL', '邮件')
        .set('WECHAT', '公众号'),
      columns: [
        {
          title: '公告标题',
          width: 600,
          dataIndex: 'title'
        },
        {
          title: '优先级',
          align: 'center',
          dataIndex: 'priority',
          width: 80,
          customRender: function(text) {
            if (text == 'LOW') {
              return '低'
            } else if (text == 'MIDDLE') {
              return '中'
            } else if (text == 'HIGH') {
              return '高'
            } else {
              return text
            }
          }
        },
        {
          title: '发布人',
          align: 'center',
          dataIndex: 'sender',
          scopedSlots: { customRender: 'sender' },
          width: 120
        },
        {
          title: '发布时间',
          align: 'center',
          width: 180,
          dataIndex: 'sendTime'
        },
        {
          title: '状态',
          align: 'center',
          width: 120,
          dataIndex: 'readFlag',
          scopedSlots: { customRender: 'readFlag' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columnsTwo: [
        {
          title: '公告标题',
          width: 300,
          dataIndex: 'templateName'
        },
        {
          title: '发送方式',
          align: 'center',
          dataIndex: 'sendTypes',
          width: 100,
          scopedSlots: { customRender: 'sendTypes' }
        },
        {
          title: '优先级',
          align: 'center',
          width: 80,
          dataIndex: 'priority',
          customRender: function(text) {
            if (text == 'LOW') {
              return '低'
            } else if (text == 'MIDDLE') {
              return '中'
            } else if (text == 'HIGH') {
              return '高'
            } else {
              return text
            }
          }
        },
        {
          title: '通知范围',
          align: 'center',
          dataIndex: 'tableScopes',
          width: 300,
          scopedSlots: { customRender: 'tableScopes' }
        },
        {
          title: '发布人',
          align: 'center',
          dataIndex: 'publishUser',
          width: 120,
          scopedSlots: { customRender: 'publishUser' }
        },
        {
          title: '发布时间',
          align: 'center',
          dataIndex: 'publishTime',
          width: 180
        },
        {
          title: '未读人员',
          dataIndex: 'noReadUsers',
          align: 'center',
          scopedSlots: { customRender: 'noReadUsers' },
          width: 280
        },
        {
          title: '状态',
          dataIndex: 'hasPublished',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'hasPublished' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 260,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: '消息内容',
          dataIndex: 'msgContent',
          scopedSlots: { customRender: 'msgContent' }
        }
      ],
      tableData: [],
      msgTableData: [],
      loading: false,
      openPath: '',
      formData: '',
      expandedRowKeys: [],
      innerData: [],
      templateName: ''
    }
  },
  created() {
    this.getMsgTableData()
  },
  computed: {
    backCss() {
      let backColor = this.$store.state.app.color
      return {
        '--theme-color': backColor
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == '2') {
        this.templateName = ''
        this.ipagination = {
          current: 1,
          pageSize: 50,
          defaultPageSize: 50,
          pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          },
          showQuickJumper: true,
          showSizeChanger: false,
          total: 0,
          msgCategory: 1
        }
        this.getTableData()
      } else {
        this.queryParam.title = ''
        this.ipagination = {
          current: 1,
          pageSize: 50,
          defaultPageSize: 50,
          pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          },
          showQuickJumper: true,
          showSizeChanger: false,
          total: 0
        }
        this.getMsgTableData()
      }
    }
  },
  methods: {
    handleTableChange2(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      console.log(123)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.getTableData()
    },
    redMsg(record) {
      putAction('sys/sysAnnouncementSend/editByAnntIdAndUserId', { anntId: record.anntId }).then(res => {
        if (res.success) {
          this.getMsgTableData()
        }
      })
    },
    pushAnnouncement(record) {
      getAction('sys/sysAnnouncementSend/reinform', { id: record.id }).then(res => {
        if (res.success) {
          this.$message.success('发布成功')
        }
      })
    },
    searchReset() {
      this.queryParam = {}
      if (this.radioValue == '2') {
        this.getTableData()
      } else {
        this.getMsgTableData()
      }
    },
    async msgdelete(record) {
      this.$confirm({
        title: '确认删除',
        content: '是否确认删除?',
        onOk: async () => {
          const res = await deleteAction('/sys/sysAnnouncementSend/delete?id=' + record.id)
          if (res.code == 200) {
            this.$message.success('删除成功')
            if (this.radioValue == '2') {
              this.getTableData()
            } else {
              this.getMsgTableData()
            }
            this.selectedRowKeys = []
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async msgdeleteBatch() {
      this.$confirm({
        title: '确认删除',
        content: '是否确认删除?',
        onOk: async () => {
          const res = await deleteAction(
            '/sys/message/sysMessageTemplate/deleteBatch?ids=' + this.selectedRowKeys.join(',')
          )
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getTableData()
            this.selectedRowKeys = []
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    editAnnouncement(record) {
      this.$refs.anctModal.open({ ...record, edit: true, type: '再次编辑' })
    },
    sendAnnouncement: simpleDebounce(async function(record) {
      this.sendAnnouncementSim(record)
    }, 500),
    async sendAnnouncementSim(record) {
      const res = await getAction('/sys/message/sysMessageTemplate/sendNotice?id=' + record.id)
      if (res.code == 200) {
        this.$message.success('发布成功')
        if (this.radioValue == '2') {
          this.getTableData()
        } else {
          this.getMsgTableData()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    async getTableData() {
      this.loading = true
      var params = this.getQueryParams()
      params.current = params.pageNo
      params.size = params.pageSize
      params.templateName = this.templateName
      const res = await getAction('/sys/message/sysMessageTemplate/notice', params)
      if (res.code == 200) {
        this.tableData = res.data.records
        this.ipagination.total = res.data.total - 0
        this.loading = false
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    },
    async getMsgTableData() {
      this.loading = true
      var params = this.getQueryParams()
      params.current = params.pageNo
      params.size = params.pageSize
      const res = await getAction('/sys/sysAnnouncementSend/getMyAnnouncementSend?msgCategory=1', params)
      if (res.code == 200) {
        this.msgTableData = res.data.records
        this.loading = false
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    },
    add() {
      this.$refs.anctModal.open(null)
    },
    expand(e, row) {
      if (e) {
        this.innerData = [row]
        this.expandedRowKeys = [row.id]
      } else {
        this.expandedRowKeys = this.expandedRowKeys.filter(item => {
          return item != row.id
        })
      }
    },
    async deleteAnnouncement(record) {
      this.$confirm({
        title: '确认删除',
        content: '该操作会删除人员已收到的公告，是否确认删除?',
        onOk: async () => {
          const res = await deleteAction('/sys/message/sysMessageTemplate/delete?msgCategory=1&id=' + record.id)
          if (res.code == 200) {
            this.$message.success('删除成功')
            if (this.radioValue == '2') {
              this.getTableData()
            } else {
              this.getMsgTableData()
            }
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    handleDetail: function(record) {
      this.$refs.sysAnnouncementModal.detail(record)
      this.$refs.sysAnnouncementModal.title = '查看'
    },
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.showAnnouncement(record)
          }
        }
      }
    },
    customRowTwo(record, index) {
      return {
        on: {
          click: () => {
            this.$refs.anctModal.open({ ...record, edit: false })
          }
        }
      }
    },
    showAnnouncement(record) {
      console.log(record)
      putAction('sys/sysAnnouncementSend/editByAnntIdAndUserId', { anntId: record.anntId }).then(res => {
        if (res.success) {
          this.getMsgTableData()
          this.syncHeadNotic(record.anntId)
        }
      })
      if (record.openType === 'component') {
        this.openPath = record.openPage
        this.formData = { id: record.busId }
        this.$refs.showDynamNotice.detail()
      } else {
        this.$refs.ShowAnnouncement.detail(record)
      }
    },
    syncHeadNotic(anntId) {
      getAction('sys/annountCement/syncNotic', { anntId: anntId })
    },
    readAll() {
      var that = this
      /*       that.$confirm({
        title: '确认操作',
        content: '是否全部标注已读?',
        onOk: function() {
          that.loading=true
          putAction('sys/sysAnnouncementSend/readAll?msgCategory=1').then(res => {
            if (res.success) {
              if (that.radioValue == '2') {
                that.getTableData()
              } else {
                that.getMsgTableData()
              }
              that.syncHeadNotic()
            }
          })
        }
      }) */
      that.loading = true
      putAction('sys/sysAnnouncementSend/readAll?msgCategory=1').then(res => {
        if (res.success) {
          if (that.radioValue == '2') {
            that.getTableData()
          } else {
            that.getMsgTableData()
          }
          that.syncHeadNotic()
        }
      })
    },
    clearReadAll() {
      var that = this
      that.$confirm({
        title: '确认操作',
        content: '是否清空已读消息?',
        onOk: function() {
          putAction('/sys/sysAnnouncementSend/clearHasRead?msgCategory=1').then(res => {
            if (res.success) {
              that.$message.success('清空成功')
              if (that.radioValue == '2') {
                that.getTableData()
              } else {
                that.getMsgTableData()
              }
              that.syncHeadNotic()
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.delete-btn:hover {
  background-color: #ff7875 !important;
  border-color: #ff7875 !important;
}
/deep/ .ant-table-expanded-row {
  background-color: #fff !important;

  td,
  th {
    background-color: #fff !important;
  }

  th {
    border: none !important;
  }
}
/deep/ .noread-user {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
  .icon {
    color: rgba(0, 0, 0, 0.65) !important;
    font-size: 30px;
    line-height: 35px;
  }
}
.noread-user:hover {
  border: 1px solid var(--theme-color);
  .icon {
    font-weight: 700 !important;
    color: var(--theme-color) !important;
  }
  cursor: pointer;
}
.ant-card-body .table-operator {
  margin-bottom: 18px;
}
/deep/ .team-table .ant-table-tbody .ant-table-row td:first-child {
  text-align: start !important;
}
.anty-row-operator button {
  margin: 0 5px;
}
.ant-btn-danger {
  background-color: #ffffff;
}
z .ant-modal-cust-warp {
  height: 100%;
}
.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}
.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden;
}
.content-table {
  img {
    max-width: 1000px;
    height: auto;
  }
}
</style>
