<template>
  <a-modal
    :title="title"
    :width="700"
    :visible="visible"
    :bodyStyle="bodyStyle"
    forceRender
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <div v-if="curRow.type != '新建' && !curRow.edit">
      <info-item :labelCol="labelCol" label="标题">{{ curRow.templateName }}</info-item>
      <info-item :labelCol="labelCol" label="发送方式">
        <a-tag v-for="item in curRow.sendTypes" :key="item">{{ sendTypeMaps[item] }}</a-tag>
      </info-item>
      <info-item :labelCol="labelCol" label="发送时间">
        {{ curRow.publishTime || '无' }}
      </info-item>
      <info-item :labelCol="labelCol" label="优先级">{{
        options.find(item => item.value == curRow.priority).label
      }}</info-item>
      <info-item :labelCol="labelCol" label="通知范围">
        <div style="height:150px;overflow-y:auto">
          <span v-for="item in curRow.tableScopes" :key="item.departName">
            <a-tag v-for="p in item.positions" :key="p.positionId">{{ item.departName + '-' + p.positionName }}</a-tag>
          </span>
        </div>
      </info-item>
      <info-item :labelCol="labelCol" label="公告内容">
        <div v-html="curRow.templateContent" class="template-content"></div>
      </info-item>
    </div>
    <a-form-model ref="form" :model="formData" :rules="rules" v-else>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标题" prop="templateName">
            <a-input placeholder="请输入内容" v-model="formData.templateName" :min="0" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="发送方式" prop="sendTypes">
            <a-checkbox-group v-model="formData.sendTypes" :options="mapToOptions(this.sendTypeMap)" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="定时发送" prop="isPublishTime">
            <a-radio-group v-model="isPublishTime">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="2">
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="发送时间" prop="publishTime">
            <a-date-picker
              format="YYYY-MM-DD HH:mm:ss"
              :disabled="isPublishTime == 2"
              style="width: 200px"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :show-time="{ format: 'HH:mm:ss', defaultValue: moment('23:59:59', 'HH:mm:ss') }"
              placeholder="请选择发送时间"
              v-model="formData.publishTime"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="优先级" prop="priority">
            <a-radio-group v-model="formData.priority" :options="options" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="通知范围" prop="rang">
            <a-tree-select
              v-model="checkRangeList"
              tree-checkable
              show-search
              :maxTagCount="6"
              @select="treeSelect"
              @change="treeChange"
              @search="treeSearch"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeData"
              placeholder="请选择通知范围"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="visible" class="form-row template-editor" :gutter="24">
        <a-col :span="24">
          <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="公告内容" prop="templateContent">
            <j-editor @onClick="onTitleClick" v-model="formData.templateContent"></j-editor>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <div v-if="curRow.type != '新建' && !curRow.edit">
        <a-button key="back" @click="handleCancel">
          关闭
        </a-button>
        <a-button v-has="'announcement:edit'" key="submit" type="primary" @click="edit">
          编辑
        </a-button>
      </div>
      <div v-else>
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapToOptions } from '@/utils/util'
import { getAction, putAction, postAction } from '@/api/manage'
import JEditor from '@/components/jeecg/JEditor'
import { getVmParentByName } from '@/utils/util'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
import moment from 'moment'
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    loadData: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    JEditor
  },
  watch: {
    /*     formData: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.templateContent.indexOf('<img') > -1 && newVal.templateContent.indexOf('oss-cn') == -1) {
          
          let url = newVal.templateContent.split('src="')[1].split('" a')[0]
          console.log(url)
          let text1 = newVal.templateContent.split('src="')[0]
          let text2 = newVal.templateContent.split('src="')[1].split('" a')[1]
          this.imgUrlToFile(url, file => {
            this.beforeUpload(file).then(() => {
              const token = Vue.ls.get(ACCESS_TOKEN)
              let formData = new FormData()
              this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
              for (let key in this.ossUploadData) {
                formData.append(key, this.ossUploadData[key])
              }
              formData.append('file', file)
              axios
                .post(this.ossUploadUrl, formData, {
                  'Content-Type': 'multipart/form-data;',
                  headers: {
                    'X-Access-Token': token
                  }
                })
                .then(() => {
                  let params = this.getSaveFileParams(file)
                  this.formData.templateContent = text1 + 'src="' + params.filePath + '" a' + text2
                  console.log(this.formData.templateContent)
                })
            })
          })
        }
      }
    } */
  },
  data() {
    let validateTemplateContent = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入公告内容'))
      } else {
        callback()
      }
    }
    let validateRnage = (rule, value, callback) => {
      if (!this.checkRangeList.length) {
        callback(new Error('请选择通知范围'))
      } else {
        callback()
      }
    }
    let validatePublishTime = (rule, value, callback) => {
      if (this.isPublishTime == 1) {
        if (!value) {
          callback(new Error('请选择发送时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      isPublishTime: 2, //是修改为否
      checkRangeList: [],
      rules: {
        templateName: [{ required: true, trigger: 'blur', message: '请输入标题' }],
        templateContent: [{ validator: validateTemplateContent, trigger: 'blur', message: '请输入公告内容' }],
        rang: [{ validator: validateRnage, trigger: 'change', message: '请选择通知范围' }],
        sendTypes: [
          {
            required: true,
            message: '请选择发送方式',
            trigger: 'change'
          }
        ],
        priority: [{ required: true, message: '请选择优先级', trigger: 'change' }],
        publishTime: [{ validator: validatePublishTime, trigger: 'change' }]
      },
      curRow: {
        type: '新建'
      },
      content: '',
      visible: false,
      title: '新建',
      bodyStyle: {
        maxHeight: '500px',
        'overflow-y': 'auto'
      },
      formData: {
        sendTypes: [],
        templateType: '1',
        detailScopes: [],
        templateContent: '',
        priority: '',
        publishTime: ''
      },
      treeData: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      sendTypeMap: new Map()
        // .set("UNKNOWN", "未知")
        // .set("SMS", "短信")
        .set('SYSTEM', '系统消息')
        .set('EMAIL', '邮件')
        .set('WECHAT', '公众号'),
      sendTypeMaps: {
        SYSTEM: '系统消息',
        EMAIL: '邮件',
        WECHAT: '公众号'
      },
      options: [
        { label: '低', value: 'LOW' },
        { label: '中', value: 'MIDDLE' },
        { label: '高', value: 'HIGH' }
      ],
      plainOptions: [
        {
          label: '邮件',
          value: 'EMAIL'
        },
        {
          label: '系统通知',
          value: 'SYSTEM'
        },
        {
          label: '微信公众号',
          value: 'WECHAT'
        }
      ]
    }
  },
  methods: {
    moment,
    mapToOptions,
    imgUrlToFile(img, callback) {
      let fileName = img.split('/').pop()
      let type = fileName.split('.').pop()
      this.dataURLtoBlob(img, function(blobData) {
        var file = new File([blobData], fileName, { type: 'image/' + type })
        callback(file)
      })
    },
    dataURLtoBlob(dataurl, callback) {
      let xhr = new XMLHttpRequest()
      xhr.open('get', dataurl, true)
      xhr.responseType = 'blob'
      xhr.onload = function(res) {
        if (this.status == 200) {
          var blob = this.response
          callback(blob)
        }
      }
      xhr.send()
    },
    getImageBlob(url) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.responseType = 'blob'
        xhr.setRequestHeader('Accept', 'image/jpeg')
        xhr.onload = function() {
          console.log(this)
          if (this.status == 200) {
            console.log(this.response)
            resolve({ ...this.response, name: '' })
          } else {
            reject(new Error('异常'))
          }
        }
        xhr.send()
        xhr.onerror = () => {
          reject(new Error('异常'))
        }
      })
    },
    edit() {
      this.curRow.edit = true
      this.formData = this.curRow
      this.checkRangeList = this.curRow.detailScopes?.map(item => {
        return item.departId + '-' + item.positionId
      })
    },
    onTitleClick() {},
    treeSelect(selectedKeys, info) {},
    treeChange(selectedKeys, info) {
      this.formData.detailScopes = selectedKeys?.map(key => {
        return { departId: key.split('-')[0], positionId: key.split('-')[1] }
      })
    },
    handleOk() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.curRow.type == '新建' || this.curRow.type == '再次编辑') {
            if (this.curRow.type == '再次编辑') {
              delete this.formData.publishUserId
              delete this.formData.readFlag
              delete this.formData.hasPublished
              delete this.formData.id
            }
            /* this.formData.publishTime = this.formData.publishTime+':00' */
            const res = await postAction('/sys/message/sysMessageTemplate/add', this.formData)
            if (res.code == 200) {
              this.$message.success('新建成功')
              this.initData()
            } else {
              this.$message.error(res.msg || res.message)
            }
          } else {
            delete this.formData.edit
            /* this.formData.publishTime = this.formData.publishTime+':00' */
            const res = await putAction('/sys/message/sysMessageTemplate/edit', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.initData()
            } else {
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.initData()
    },
    initData() {
      this.formData = {
        sendTypes: [],
        templateType: '1',
        detailScopes: [],
        templateContent: '',
        priority: ''
      }
      this.isPublishTime = 2
      this.curRow = { type: '新建' }
      this.checkRangeList = []
      if (this.$refs.form) this.$refs.form.resetFields()
      this.visible = false
      this.loadData()
    },
    treeSearch() {},
    async open(curRow) {
      this.curRow = curRow || { type: '新建' }
      if(this.curRow.publishTime){
        this.isPublishTime=1
      }else{
        this.isPublishTime=2
      }
      if (this.curRow.type != '新建' && this.curRow.edit) {
        this.curRow.publishTime = null
        this.isPublishTime=2
        this.formData = this.curRow
        this.checkRangeList = this.curRow.detailScopes?.map(item => {
          return item.departId + '-' + item.positionId
        })
      }
      this.title = this.curRow.type != '新建' ? (this.curRow.edit ? '编辑' : '预览') : '新建'
      this.visible = true
      const res = await getAction('/sys/annountCement/usersToSelect')
      if (res.code == 200) {
        res.data = res.data.length && res.data[0].children
        res.data?.forEach(i => {
          i.value = i.departId
          i.title = i.departName
          i.key = i.departId
          i.children?.forEach(d => {
            d.value = d.departId
            d.title = d.departName
            d.key = d.departId
            d.positions?.forEach(p => {
              p.value = d.departId + '-' + p.positionId
              p.title = p.positionName
              p.key = d.departId + '-' + p.positionId
            })
            d.children = d.positions
          })
        })
        this.treeData = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .template-content p {
  font-size: 14px !important;
  margin-top: 0 !important;
  img {
    max-width: 450px !important;
    height: auto;
  }
}
.template-content {
  height: 250px !important;
  max-width: 100%;
  overflow-y: auto;
}
.ant-checkbox-group {
  display: flex;
  height: 38px;
  align-items: center;
}
/* /deep/ .template-editor .tox {
  height: 300px !important;
} */
</style>
