var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 700,
        visible: _vm.visible,
        bodyStyle: _vm.bodyStyle,
        forceRender: "",
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _vm.curRow.type != "新建" && !_vm.curRow.edit
        ? _c(
            "div",
            [
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "标题" } },
                [_vm._v(_vm._s(_vm.curRow.templateName))]
              ),
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "发送方式" } },
                _vm._l(_vm.curRow.sendTypes, function (item) {
                  return _c("a-tag", { key: item }, [
                    _vm._v(_vm._s(_vm.sendTypeMaps[item])),
                  ])
                }),
                1
              ),
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "发送时间" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.curRow.publishTime || "无") +
                      "\n    "
                  ),
                ]
              ),
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "优先级" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.options.find(
                        (item) => item.value == _vm.curRow.priority
                      ).label
                    )
                  ),
                ]
              ),
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "通知范围" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "150px", "overflow-y": "auto" } },
                    _vm._l(_vm.curRow.tableScopes, function (item) {
                      return _c(
                        "span",
                        { key: item.departName },
                        _vm._l(item.positions, function (p) {
                          return _c("a-tag", { key: p.positionId }, [
                            _vm._v(
                              _vm._s(item.departName + "-" + p.positionName)
                            ),
                          ])
                        }),
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "info-item",
                { attrs: { labelCol: _vm.labelCol, label: "公告内容" } },
                [
                  _c("div", {
                    staticClass: "template-content",
                    domProps: { innerHTML: _vm._s(_vm.curRow.templateContent) },
                  }),
                ]
              ),
            ],
            1
          )
        : _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "标题",
                            prop: "templateName",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入内容", min: 0 },
                            model: {
                              value: _vm.formData.templateName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "templateName", $$v)
                              },
                              expression: "formData.templateName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "发送方式",
                            prop: "sendTypes",
                          },
                        },
                        [
                          _c("a-checkbox-group", {
                            attrs: {
                              options: _vm.mapToOptions(this.sendTypeMap),
                            },
                            model: {
                              value: _vm.formData.sendTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sendTypes", $$v)
                              },
                              expression: "formData.sendTypes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "定时发送",
                            prop: "isPublishTime",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.isPublishTime,
                                callback: function ($$v) {
                                  _vm.isPublishTime = $$v
                                },
                                expression: "isPublishTime",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("\n              是\n            "),
                              ]),
                              _c("a-radio", { attrs: { value: 2 } }, [
                                _vm._v("\n              否\n            "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "发送时间",
                            prop: "publishTime",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              format: "YYYY-MM-DD HH:mm:ss",
                              disabled: _vm.isPublishTime == 2,
                              valueFormat: "YYYY-MM-DD HH:mm:ss",
                              "show-time": {
                                format: "HH:mm:ss",
                                defaultValue: _vm.moment(
                                  "23:59:59",
                                  "HH:mm:ss"
                                ),
                              },
                              placeholder: "请选择发送时间",
                            },
                            model: {
                              value: _vm.formData.publishTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "publishTime", $$v)
                              },
                              expression: "formData.publishTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "优先级",
                            prop: "priority",
                          },
                        },
                        [
                          _c("a-radio-group", {
                            attrs: { options: _vm.options },
                            model: {
                              value: _vm.formData.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "priority", $$v)
                              },
                              expression: "formData.priority",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "通知范围",
                            prop: "rang",
                          },
                        },
                        [
                          _c("a-tree-select", {
                            attrs: {
                              "tree-checkable": "",
                              "show-search": "",
                              maxTagCount: 6,
                              dropdownStyle: {
                                maxHeight: "400px",
                                overflow: "auto",
                              },
                              "tree-data": _vm.treeData,
                              placeholder: "请选择通知范围",
                            },
                            on: {
                              select: _vm.treeSelect,
                              change: _vm.treeChange,
                              search: _vm.treeSearch,
                            },
                            model: {
                              value: _vm.checkRangeList,
                              callback: function ($$v) {
                                _vm.checkRangeList = $$v
                              },
                              expression: "checkRangeList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.visible
                ? _c(
                    "a-row",
                    {
                      staticClass: "form-row template-editor",
                      attrs: { gutter: 24 },
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "公告内容",
                                prop: "templateContent",
                              },
                            },
                            [
                              _c("j-editor", {
                                on: { onClick: _vm.onTitleClick },
                                model: {
                                  value: _vm.formData.templateContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "templateContent",
                                      $$v
                                    )
                                  },
                                  expression: "formData.templateContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _c("template", { slot: "footer" }, [
        _vm.curRow.type != "新建" && !_vm.curRow.edit
          ? _c(
              "div",
              [
                _c(
                  "a-button",
                  { key: "back", on: { click: _vm.handleCancel } },
                  [_vm._v("\n        关闭\n      ")]
                ),
                _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "announcement:edit",
                        expression: "'announcement:edit'",
                      },
                    ],
                    key: "submit",
                    attrs: { type: "primary" },
                    on: { click: _vm.edit },
                  },
                  [_vm._v("\n        编辑\n      ")]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "a-button",
                  { key: "back", on: { click: _vm.handleCancel } },
                  [_vm._v("\n        取消\n      ")]
                ),
                _c(
                  "a-button",
                  {
                    key: "submit",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleOk },
                  },
                  [_vm._v("\n        确定\n      ")]
                ),
              ],
              1
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }