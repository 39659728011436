var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { style: _vm.backCss, attrs: { bordered: false } },
    [
      _c(
        "div",
        { staticClass: "table-page-search-wrapper" },
        [
          _vm.radioValue == "1"
            ? _c(
                "a-form",
                {
                  attrs: { layout: "inline" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入标题" },
                                on: { pressEnter: _vm.getMsgTableData },
                                model: {
                                  value: _vm.queryParam.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "title", $$v)
                                  },
                                  expression: "queryParam.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("a-col", { attrs: { span: 8 } }, [
                        _c(
                          "span",
                          {
                            staticClass: "table-page-search-submitButtons",
                            staticStyle: { float: "left", overflow: "hidden" },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", icon: "search" },
                                on: { click: _vm.getMsgTableData },
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: { type: "primary", icon: "reload" },
                                on: { click: _vm.searchReset },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "a-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "end",
                            float: "right",
                          },
                          attrs: { span: 4 },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mr-3",
                              attrs: { type: "primary", icon: "book" },
                              on: { click: _vm.clearReadAll },
                            },
                            [_vm._v("清空已读")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "book" },
                              on: { click: _vm.readAll },
                            },
                            [_vm._v("全标已读")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { height: "56px" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入标题" },
                                on: { pressEnter: _vm.getTableData },
                                model: {
                                  value: _vm.templateName,
                                  callback: function ($$v) {
                                    _vm.templateName = $$v
                                  },
                                  expression: "templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("a-col", { attrs: { span: 8 } }, [
                        _c(
                          "span",
                          {
                            staticClass: "table-page-search-submitButtons",
                            staticStyle: { float: "left", overflow: "hidden" },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", icon: "search" },
                                on: { click: _vm.getTableData },
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: { type: "primary", icon: "reload" },
                                on: { click: _vm.searchReset },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "a-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "end",
                            float: "right",
                          },
                          attrs: { span: 4 },
                        },
                        [
                          _vm.selectedRowKeys.length
                            ? _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "announcement:list:delete",
                                      expression: "'announcement:list:delete'",
                                    },
                                  ],
                                  staticClass: "mr-3 delete-btn",
                                  staticStyle: {
                                    color: "#fff",
                                    "background-color": "#ff4d4f",
                                    "border-color": "#ff4d4f",
                                  },
                                  attrs: { icon: "delete" },
                                  on: { click: _vm.msgdeleteBatch },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "announcement:add",
                                  expression: "'announcement:add'",
                                },
                              ],
                              attrs: { type: "primary", icon: "plus" },
                              on: { click: _vm.add },
                            },
                            [_vm._v("新建")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px", "padding-bottom": "20px" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            },
            [
              _c(
                "a-radio",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "announcement:list:view",
                      expression: "'announcement:list:view'",
                    },
                  ],
                  attrs: { value: "1" },
                },
                [_vm._v("公告列表")]
              ),
              _c(
                "a-radio",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "announcement:view",
                      expression: "'announcement:view'",
                    },
                  ],
                  attrs: { value: "2" },
                },
                [_vm._v("发布公告")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.radioValue == "1",
            expression: "radioValue == '1'",
          },
        ],
        ref: "table",
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.msgTableData,
          pagination: _vm.ipagination,
          customRow: _vm.customRow,
          expandedRowKeys: _vm.expandedRowKeys,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 446px)" },
        },
        on: { expand: _vm.expand, change: _vm.handleTableChange2 },
        scopedSlots: _vm._u([
          {
            key: "readFlag",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(record.readFlag == "1" ? "已读" : "未读") +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "sender",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      record.template &&
                        record.template.publishUser &&
                        record.template.publishUser.userName
                    ) +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "announcement:list:delete",
                          expression: "'announcement:list:delete'",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.msgdelete(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "15px" },
                      on: {
                        click: function ($event) {
                          return _vm.redMsg(record)
                        },
                      },
                    },
                    [_vm._v("标注已读")]
                  ),
                ]
              )
            },
          },
          {
            key: "expandedRowRender",
            fn: function (text) {
              return _c("a-table", {
                staticClass: "content-table",
                staticStyle: { "background-color": "#fff" },
                attrs: {
                  columns: _vm.innerColumns,
                  "data-source": _vm.innerData,
                  pagination: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "msgContent",
                    fn: function (text, record) {
                      return [
                        _c("div", {
                          staticStyle: {
                            "white-space": "pre-line",
                            "text-align": "left",
                          },
                          domProps: { innerHTML: _vm._s(record.msgContent) },
                        }),
                      ]
                    },
                  },
                ]),
              })
            },
          },
        ]),
      }),
      _c("a-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.radioValue == "2",
            expression: "radioValue == '2'",
          },
          {
            name: "has",
            rawName: "v-has",
            value: "announcement:view",
            expression: "'announcement:view'",
          },
        ],
        ref: "table",
        attrs: {
          size: "default",
          rowKey: "id",
          columns: _vm.columnsTwo,
          dataSource: _vm.tableData,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          customRow: _vm.customRowTwo,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
          scroll: { x: "max-content", y: "calc(100vh - 446px)" },
        },
        on: { change: _vm.handleTableChange2 },
        scopedSlots: _vm._u([
          {
            key: "sendTypes",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                _vm._l(record.sendTypes, function (item) {
                  return _c("a-tag", { key: item }, [
                    _vm._v(_vm._s(_vm.sendTypeMap[item])),
                  ])
                }),
                1
              )
            },
          },
          {
            key: "hasPublished",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(record.hasPublished ? "已发布" : "未发布") +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "publishUser",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      record.publishUser ? record.publishUser.userName : ""
                    ) +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "tableScopes",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  _vm._l(record.tableScopes, function (item, index) {
                    return [
                      index < 2
                        ? _c(
                            "div",
                            { key: item.departName },
                            [
                              _c("a-tag", [_vm._v(_vm._s(item.departName))]),
                              _c("span", [_vm._v("...")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            },
          },
          {
            key: "noReadUsers",
            fn: function (text, record) {
              return _c("span", {}, [
                record.noReadUsers.length < 4
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                        },
                      },
                      _vm._l(record.noReadUsers, function (item) {
                        return _c("span", { key: item.userId }, [
                          item.avatar
                            ? _c("img", {
                                staticStyle: {
                                  width: "35px",
                                  height: "35px",
                                  "border-radius": "50%",
                                  "margin-left": "15px",
                                },
                                attrs: { src: item.avatar, alt: "" },
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  "border-radius": "50%",
                                },
                                attrs: {
                                  src: require("./../../assets/tx.png"),
                                  alt: "",
                                },
                              }),
                        ])
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                        },
                      },
                      [
                        _vm._l(
                          record.noReadUsers.filter((i, index) => {
                            return index < 3
                          }),
                          function (item) {
                            return _c("img", {
                              key: item.userId,
                              staticStyle: {
                                width: "35px",
                                height: "35px",
                                "border-radius": "50%",
                                "margin-left": "15px",
                              },
                              attrs: { src: item.avatar },
                            })
                          }
                        ),
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              overlayClassName: "user-msg-tooltip",
                              color: "white",
                              placement: "bottom",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "padding-left": "15px",
                                              "font-weight": "700",
                                            },
                                          },
                                          [_vm._v("未读人员")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              height: "300px",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          _vm._l(
                                            record.noReadUsers,
                                            function (item) {
                                              return _c(
                                                "p",
                                                { key: item.userId },
                                                [
                                                  _c("img", {
                                                    key: item.userId,
                                                    staticStyle: {
                                                      width: "35px",
                                                      height: "35px",
                                                      "border-radius": "50%",
                                                      "margin-left": "15px",
                                                    },
                                                    attrs: { src: item.avatar },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "15px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.userName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _c("span", { staticClass: "noread-user" }, [
                                _c("span", {
                                  staticClass:
                                    "iconfont icon-24gl-ellipsis icon",
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      2
                    ),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "announcement:delete",
                          expression: "'announcement:delete'",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.deleteAnnouncement(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "announcement:send",
                          expression: "'announcement:send'",
                        },
                      ],
                      staticStyle: { "margin-left": "15px" },
                      attrs: { disabled: record.hasPublished },
                      on: {
                        click: function ($event) {
                          return _vm.sendAnnouncement(record)
                        },
                      },
                    },
                    [_vm._v("发布")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "announcement:edit",
                          expression: "'announcement:edit'",
                        },
                      ],
                      staticStyle: { "margin-left": "15px" },
                      on: {
                        click: function ($event) {
                          return _vm.editAnnouncement(record)
                        },
                      },
                    },
                    [_vm._v("再次编辑")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "announcement:edit",
                          expression: "'announcement:edit'",
                        },
                      ],
                      staticStyle: { "margin-left": "15px" },
                      on: {
                        click: function ($event) {
                          return _vm.pushAnnouncement(record)
                        },
                      },
                    },
                    [_vm._v("再次提醒")]
                  ),
                ]
              )
            },
          },
        ]),
      }),
      _c("show-announcement", { ref: "ShowAnnouncement" }),
      _c("anct-modal", {
        ref: "anctModal",
        attrs: { loadData: _vm.getTableData },
      }),
      _c("dynamic-notice", {
        ref: "showDynamNotice",
        attrs: { path: _vm.openPath, formData: _vm.formData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }